exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-contentful-blog-post-title-js": () => import("./../../../src/pages/blog/{ContentfulBlogPost.title}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-title-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-tag-contentful-blog-post-tag-name-js": () => import("./../../../src/pages/blog/tag/{ContentfulBlogPostTag.name}.js" /* webpackChunkName: "component---src-pages-blog-tag-contentful-blog-post-tag-name-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-and-vision-js": () => import("./../../../src/pages/mission-and-vision.js" /* webpackChunkName: "component---src-pages-mission-and-vision-js" */),
  "component---src-pages-principles-and-values-js": () => import("./../../../src/pages/principles-and-values.js" /* webpackChunkName: "component---src-pages-principles-and-values-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-and-prices-js": () => import("./../../../src/pages/services-and-prices.js" /* webpackChunkName: "component---src-pages-services-and-prices-js" */),
  "component---src-pages-support-the-cause-js": () => import("./../../../src/pages/support-the-cause.js" /* webpackChunkName: "component---src-pages-support-the-cause-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-zofia-s-story-js": () => import("./../../../src/pages/zofia-s-story.js" /* webpackChunkName: "component---src-pages-zofia-s-story-js" */)
}

